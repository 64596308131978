import { Outlet } from "@remix-run/react";
import { Footer } from "~/components/landingpage/Footer";
import { Navbar } from "~/components/landingpage/Navbar";

export default function Layout() {
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <Navbar />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
