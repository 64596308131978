import { Link } from "@remix-run/react";
import { MenuIcon, MoveRightIcon, X } from "lucide-react";
import { useState } from "react";
import { $path } from "remix-routes";
import { Container } from "~/components/landingpage/Container";
import { Button } from "~/components/ui/button";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigation = [
    {
      label: "Homepage",
      to: "/",
    },
    {
      label: "Preise",
      to: "/pricing",
    },
  ];

  return (
    <Container className="w-full">
      <nav className="container relative mx-auto flex flex-wrap items-center justify-between p-4 sm:justify-between md:p-8 xl:px-1">
        {/* Logo  */}
        <Link to="/">
          <span className="flex items-center gap-2 space-x-2 text-2xl font-medium dark:text-gray-100">
            <img src="/aifred_icon.jpg" className="h-12 w-12 rounded-full" />
            <span>AIFred</span>
          </span>
        </Link>

        {/* Mobile menu button */}
        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <X className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          ) : (
            <MenuIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          )}
        </button>

        {/* Desktop menu */}
        <div className="hidden text-center sm:flex sm:items-center">
          <ul className="flex-1 list-none items-center justify-end pt-6 sm:flex sm:pt-0">
            {navigation.map((menu, index) => (
              <li className="nav__item mr-3" key={index}>
                <Link
                  to={menu.to}
                  className="inline-block rounded-md px-4 py-2 text-lg font-normal text-gray-800 no-underline hover:text-indigo-500 focus:bg-indigo-100 focus:text-indigo-500 focus:outline-none dark:text-gray-200 dark:focus:bg-gray-800"
                >
                  {menu.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile menu */}
        <div
          className={`${
            isMenuOpen ? "flex" : "hidden"
          } absolute left-0 right-0 top-full z-50 w-full flex-col rounded-md bg-white p-4 shadow-2xl dark:bg-gray-900 sm:hidden`}
        >
          <ul className="flex flex-col space-y-4">
            {navigation.map((menu, index) => (
              <li key={index}>
                <Link
                  to={menu.to}
                  className="block rounded-md px-4 py-2 text-lg font-normal text-gray-800 no-underline hover:text-indigo-500 focus:bg-indigo-100 focus:text-indigo-500 focus:outline-none dark:text-gray-200 dark:focus:bg-gray-800"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {menu.label}
                </Link>
              </li>
            ))}
            <li>
              <Button
                className="w-full bg-indigo-500 text-lg"
                size={"lg"}
                asChild
                onClick={() => setIsMenuOpen(false)}
              >
                <Link to={$path("/app")}>
                  <MoveRightIcon className="mr-2" /> Zur App
                </Link>
              </Button>
            </li>
          </ul>
        </div>

        {/* Desktop CTA Button */}
        <div className="hidden sm:block">
          <Button className="bg-indigo-500 text-lg" size={"lg"} asChild>
            <Link to={$path("/app")}>
              <MoveRightIcon className="mr-2" /> Zur App
            </Link>
          </Button>
        </div>
      </nav>
    </Container>
  );
};
